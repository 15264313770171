import * as React from "react"
import PropTypes from "prop-types"
import Item from "./item"

const Menu = ({ items, className }) => {
	if (items != null) {
		let _items = []

		for (let url in items) {
			if(items.hasOwnProperty(url)) {
				_items.push(<Item link={ url } label={ items[url] } key={ url } />)
			}
		}

  		return (<nav className={className}>
	  	<ul>
			{_items}
		</ul>
  		</nav>
		);
	}
}

Menu.propTypes = {
  siteTitle: PropTypes.string,
  className: PropTypes.string
}

Menu.defaultProps = {
  items: {},
  className: ""
}

export default Menu
