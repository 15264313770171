import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Item = ({link, label}) => (
	<li>
		<Link activeClassName="active" to={ link }>
			{ label }
			</Link>
	</li>
)

Item.propTypes = {
	link: PropTypes.string,
	label: PropTypes.string
 }
 
 Item.defaultProps = {
	link: "",
	label: ""
 }
 
 export default Item
 